<template>
<bullet-card :image="image">
    <div slot="title">FFL / NFA TRANSFERS</div>

        <p>
            INBOUND TRANSFERS $40/$100
        </p>

        <p>
            OUTBOUND FFL TRANSFERS PRICED INSTORE. MUST BRING YOUR DRIVERS LICENSE AND THE RECEIVING SHOPS FFL.
        </p>
        


    <v-btn slot="action" bottom right light color="amber darken-1">Learn More</v-btn>
</bullet-card>
</template>

<script>
    const badge = require('@/assets/badge.jpg');

    import bulletCard from "@/components/bullet-card.vue"

    export default {
        name: 'ProShopCard',
        components: {
            bulletCard
        },
        computed: {
            image() {
                return badge;
            },
        }
    }
</script>

<style lang="scss">

</style>

