<template>
    <div class="main-foooter">
        <v-img :src="require('@/assets/GrittyMetal.jpg')" class="footer-background">
                <v-toolbar class="footer-social-links" dark flat>
                    <v-btn icon dark>
                        <v-icon size="40">mdi-email</v-icon>
                    </v-btn>
                    <v-btn icon dark>
                        <v-icon size="40">mdi-phone</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn icon dark>
                        <v-icon size="48">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn icon dark>
                        <v-icon size="48">mdi-linkedin</v-icon>
                    </v-btn>
                    <v-btn icon dark>
                        <v-icon size="48">mdi-twitter</v-icon>
                    </v-btn>
                    <v-btn icon dark>
                        <v-icon size="48">mdi-youtube</v-icon>
                    </v-btn>
                    <v-btn icon dark>
                        <v-icon size="48">mdi-instagram</v-icon>
                    </v-btn>
                </v-toolbar>
            <v-container>

                <br />

                <v-layout>
                    <v-row>
                        <v-col cols="12" sm="6" lg="4" xl="2">
                            <v-card dark class="store-info-card">
                                <a target="_blank" href="https://www.google.com/maps/place/Battle+Rifle+Company/@29.5288131,-95.2999932,11z/data=!3m1!5s0x86409cf7d8db8dc1:0x8612671f8656760c!4m5!3m4!1s0x863f6222b682d43d:0x199445a79b4c351a!8m2!3d29.5516441!4d-95.1138">
                                    <v-img :src="require('@/assets/map.png')" />
                                </a>
                                <v-card-text>
                                    <v-list-item>
                                        <v-list-item-avatar tile v-if="$vuetify.breakpoint.mdAndUp">
                                            <v-icon size="48">mdi-domain</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>17313 El Camino Real</v-list-item-title>
                                            <v-list-item-subtitle>
                                                Houston, TX 77058
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" xl="2">
                            <v-card dark class="store-info-card">
                                <v-card-text>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>
                                                Pro Shop Hours
                                            </v-list-item-subtitle>
                                            <v-list-item-title>
                                                Tue-Sat, 9am to 6am CST
                                            </v-list-item-title>
                                            <br />
                                            <v-divider />
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <div>
                                                <v-btn color="primary" small href="mailto:INFO@BATTLERIFLECOMPANY.COM">
                                                    info@BATTLERIFLECOMPANY.COM
                                                </v-btn>
                                                <br />
                                                <v-btn small color="primary" href="tel:281-777-0316">
                                                    281-777-0316
                                                </v-btn>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-container>

        </v-img>
        <v-toolbar color="amber darken-1" dense>
            <v-spacer />
            <div>© Copyright {{new Date().getFullYear()}} Battle Rifle Company</div>
        </v-toolbar>
    </div>
</template>

<script>


    export default {
        name: 'MainFooter',
        components: {
        },
        computed: {
        }
    }
</script>

<style lang="scss">
.footer-background
{
    .v-image__image
    {
        filter: brightness(0.8);
    }
}

.main-footer {
    box-shadow: -10px 0px 50px 0px rgba(255,0,0,1) inset !important;
    padding: 0px !important;
    
}
.footer-social-links {
    background-color: rgba(0, 0, 0, .4) !important;
}
</style>

