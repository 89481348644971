<template>
<bullet-card :image="image">
    <div slot="title">FIREARMS TRAINING</div>

        <p>TEXAS LTC COURSES. $85 CLASS FEE, CALL OR COME BY TO SIGN UP. ASK FOR JIM!</p>

        <v-divider />
        <br />

        <p>BASIC PISTOL AND RIFLE INSTRUCTION FOR CIVILIAN. CALL OR COME BY TO SIGN UP. ASK FOR JOE!</p>

        <v-divider />
        <br />

        <p>PROFESSIONAL TACTICAL TRAINING PROVIDED BY <v-btn light color="amber darken-1" href="https://www.awattclass.com/">awattclass.com <v-icon>mdi-open-in-new</v-icon></v-btn></p>

</bullet-card>
</template>

<script>
    const img = require('@/assets/bamf.jpg');

    import bulletCard from "@/components/bullet-card.vue"

    export default {
        name: 'ProShopCard',
        components: {
            bulletCard
        },
        computed: {
            image() {
                return img;
            },
        }
    }
</script>

<style lang="scss">

</style>







