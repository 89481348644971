<template>
<v-app dark class="background">

  <main-header />
    <!-- {{$vuetify.breakpoint.name}} -->
    <v-img :src="require('@/assets/Rifle.jpg')" class="main-background">
        <v-container>

            <v-main>
                <router-view />
            </v-main>

        </v-container>
    </v-img>
    <main-footer />
    <!-- <div class="overlay"></div> -->
</v-app>
</template>
<script>

import MainHeader from "@/components/main-header"
import MainFooter from "@/components/main-footer"


export default {
    name: 'Home',
    components: {
      MainHeader,MainFooter

    },
    data()
    {
      return {
      };
    },
}
</script>
<style lang="scss">

.main-background .v-image__image 
{
  filter:brightness(.1);
}
.overlay
{
  // background-color:red;
  box-shadow: -10px 0px 50px 0px rgba(0,0,0,1) inset;
  position:absolute;
  width:100vw;
  height:100%;

}
</style>
