<template>
<bullet-card :image="image">
    <div slot="title">ON-SITE GUNSMITHING</div>

    <p>OUR GUNSMITHS ARE GRADUATES OF ACCREDITED SCHOOLS.</p>
    <v-list color="transparent" dark>
        <v-list-item>$75 MANUAL LABOR</v-list-item>
        <v-divider />
        <v-list-item>$150 MACHINE LABOR</v-list-item>
        <v-divider />
        <v-list-item>$125 STOCK WORK</v-list-item>
    </v-list>

    <v-btn slot="action" bottom right light color="amber darken-1">Learn More</v-btn>
</bullet-card>
</template>

<script>
    const badge = require('@/assets/Smith.jpg');

    import bulletCard from "@/components/bullet-card.vue"

    export default {
        name: 'ProShopCard',
        components: {
            bulletCard
        },
        computed: {
            image() {
                return badge;
            },
        }
    }
</script>

<style lang="scss">

</style>
