<template>
<v-card dark width="100%" color="transparent" tile :style="backgroundStyle" class="bullet-card">

    <div class="image" :style="imageStyle"></div>

    <v-card class="stretchy" tile>

        <v-card-title class="title">
            <slot name="title"></slot>
        </v-card-title>

        <v-card-text>
            <slot></slot>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <slot name="action"></slot>
        </v-card-actions>
    </v-card>
</v-card>
</template>

<script>
export default {
    name: 'ProShopCard',
    props: {
        imageCss: {
          type:Object,
          default:() => { return { "min-height" :"200px"} }
        },
        image: {
            type: String
        },
        verticalBreakpoints: {
            type: Array,
            default: () => ["xs"]
        }
    },
    computed: {
        imageStyle() {
            var val = Object.assign({}, this.imageCss, {
                "background-image": `url(${this.image})`,
            });

            return val;
        },

        backgroundStyle() {
            var val = {
                "width": "100%",
            };

            if (this.verticalBreakpoints.indexOf(this.$vuetify.breakpoint.name) >= 0) {
                val["flex-direction"] = "column";
            }

            return val;
        }
    }
}
</script>

<style lang="scss">
.bullet-card:hover
{
  .image {
    filter:brightness(1);
    box-shadow: initial;
  }
}
.bullet-card {
    display: flex !important;
    height: 100%;
    flex-direction: row;

    >* {
        width: 100%;
    }

    .title {
        word-break: normal;
    }

    .stretchy {
        flex-basis: 100%;
    }

    .image
    {
        box-shadow: -10px 0px 30px 0px rgba(0,0,0,.5) inset;
        transition: all ease .3s;
        filter:brightness(.6);
        flex-basis: 100%;
        background-size: cover;
        background-position: center center;
    }
}
</style>
