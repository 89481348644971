<template>
<div class="Home">


<!-- {{$vuetify.breakpoint.name}} -->
    <v-layout>
        <v-row wrap>

            <v-col cols="12" sm="12" md="12" lg="8">
              <pro-shop-card />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <gun-smith-card />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <leo-card />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <paint-card />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <ffl-card />
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <training-card />
            </v-col>

        </v-row>
    </v-layout>

</div>
</template>

<script>

import ProShopCard from "@/components/pro-shop-card"
import GunSmithCard from "@/components/gun-smith-card"
import LeoCard from "@/components/leo-card"
import PaintCard from "@/components/paint-card"
import fflCard from  "@/components/ffl-card"
import TrainingCard from "@/components/training-card"



export default {
    name: 'Home',
    components: {
      ProShopCard,GunSmithCard,LeoCard,PaintCard,fflCard,TrainingCard
    },
    data()
    {
      return {
      };
    },
}
</script>

<style lang="scss">
.btn-holder {
    padding: 32px
}

.parallax {
    .v-parallax__content {
        // border: 1px red solid !important;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    }

}


.stretchy {
    height:100%;
    display: flex !important;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .v-card__title {
        order:1;
        flex-grow:0;
        flex-shrink:0;
    }
    .v-card__text {
        order:2;
        flex-grow:1;
        overflow-y:auto;
        flex-basis: auto;
        display: flex;
        flex-flow: column;
        overflow: auto;

    }

    .v-card__actions {
        order:2;
        flex-grow: 0;
        flex-basis:50px;
    }
}

</style>
