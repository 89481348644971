<template>
<bullet-card :image="image">
    <div slot="title">LAW ENFORCEMENT</div>

        <p>SPECIALIZED SERVICES FOR L.E. CUSTOMERS AND DEPARTMENTS.</p>
        <v-list color="transparent" dark>
            <v-list-item>MAINTENANCE & SERVICES</v-list-item>
            <v-divider />
            <v-list-item>UPGRADES</v-list-item>
            <v-divider />
            <v-list-item>TRADE-INS</v-list-item>
            <v-divider />
            <v-list-item>BUILD TO SPEC</v-list-item>
            <v-divider />
            <v-list-item>MALFUNCTION DIAGNOSIS</v-list-item>
        </v-list>


    <!-- <v-btn slot="action" bottom right light color="amber darken-1">Learn More</v-btn> -->
</bullet-card>
</template>

<script>
    const badge = require('@/assets/badge.jpg');

    import bulletCard from "@/components/bullet-card.vue"

    export default {
        name: 'ProShopCard',
        components: {
            bulletCard
        },
        computed: {
            image() {
                return badge;
            },
        }
    }
</script>

<style lang="scss">

</style>

