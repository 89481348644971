<template>
<div>

    <v-app-bar app dark dense class="header-bar">

        <v-spacer />
        <div>
            <!-- Pro Shop Hours :
            <v-chip label color="transparent">Tue-Sat, 9am to 6am CST</v-chip> -->
            <v-btn outlined color="amber darken-1" href="tel:281-777-0316">
                281-777-0316
            </v-btn>
        </div>
        <v-btn outlined color="amber darken-1" href="mailto:INFO@BATTLERIFLECOMPANY.COM" v-if="$vuetify.breakpoint.smAndUp || true">
            Contact Us
        </v-btn>

    </v-app-bar>

    <v-parallax :src="require('@/assets/ar-1.jpg')" class="parallax" height="500">
        <v-container class="header-container">
            <v-card dark class="stretchy" color="transparent" flat tile>
                <v-card-text>
                    <v-layout>
                        <v-row>
                            <v-col sm="6" lg="4">
                                <v-card color="transparent" flat>
                                    <v-img :src="require('@/assets/BRC-Yellow-Logo-01.png')" width="100%" contain></v-img>
                                    <v-card-text>
                                        <p style="font-size:1.0em;line-height:1.4em">PRODUCTION AND CUSTOM RIFLE MANUFACTURING FOR LAW ENFORCEMENT, GOVERNMENT AGENCY, MILITARY, EXPORT AND CIVILIAN.</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-container>
        <v-btn target="_blank" href="https://battlerifleproshop.com" large absolute bottom right color="amber darken-1">Battle Rifle Pro Shop</v-btn>
    </v-parallax>
</div>
</template>

<script>
export default {
    name: 'MainHeader',
    components: {},
    computed: {}
}
</script>

<style lang="scss">
.parallax {
    margin-top:48px;
    box-shadow: -10px 0px 50px 0px rgba(0, 0, 10, .5) inset;

    .v-parallax__content {
        padding: 0px;
    }

    .header-bar {
        height: 48px !important;
        // background-color:rgba(0,0,0,.6) !important;
    }
}
</style>
