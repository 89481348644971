<template>
<bullet-card :image="image">
    <div slot="title">CUSTOM COATINGS</div>

        <p>ON-SITE PAINT. CUSTOM PAINT JOBS USING DURACOAT, CERAKOTE, AND KG GUN KOTE.</p>
        <v-list color="transparent" dark>
            <v-list-item>$250 - $550 HANDGUNS</v-list-item>
            <v-divider />
            <v-list-item>$300 - $600 LONG GUNS</v-list-item>
            <v-divider />
            <v-list-item>SMALL PARTS QUOTED BY JOB, $100 MINIMUM</v-list-item>
        </v-list>


    <v-btn slot="action" bottom right light color="amber darken-1">Learn More</v-btn>
</bullet-card>
</template>

<script>
    const badge = require('@/assets/Coating.jpg');

    import bulletCard from "@/components/bullet-card.vue"

    export default {
        name: 'ProShopCard',
        components: {
            bulletCard
        },
        computed: {
            image() {
                return badge;
            },
        }
    }
</script>

<style lang="scss">

</style>



