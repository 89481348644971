<template>
<bullet-card :image="image">

    <div slot="title">RETAIL PRO SHOP</div>

    <v-list color="transparent" dark>
        <v-list-item>FIREARMS</v-list-item>
        <v-divider />
        <v-list-item>OPTICS</v-list-item>
        <v-divider />
        <v-list-item>AMMUNITION</v-list-item>
        <v-divider />
        <v-list-item>BODY ARMOR</v-list-item>
        <v-divider />
        <v-list-item>ACCESSORIES</v-list-item>
    </v-list>

    <v-btn slot="action" bottom right light color="amber darken-1">Pro Shop</v-btn>
</bullet-card>
</template>

<script>
const badge = require('@/assets/ProShop.jpg');

import bulletCard from "@/components/bullet-card.vue"

export default {
    name: 'ProShopCard',
    components: {
        bulletCard
    },
    computed: {
        image()
        {
            return badge;
        },
    }
}
</script>

<style lang="scss">

</style>
